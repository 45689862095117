import { markCompletedExercises } from "./exercise-list";
import { queryVSCodeLinks } from "./queries";
import { showSubmissionsIfAvailable } from "./submissions";

export function initPythonExercisePlugin() {
  const token = localStorage.getItem("active-handout--user-token");
  showSubmissionsIfAvailable(token);
  if (!token) {
    return;
  }

  markCompletedExercises(token);

  const vscodeLinks = queryVSCodeLinks();
  vscodeLinks.forEach((link) => {
    link.href = link.href + "&token=" + token;
  });
}