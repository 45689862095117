const PARTITION_SIZE = 5;

export function fetchSubmissions(token, slug, fetchAll) {  
  const slugs = [];
  if (typeof slug === "string") {
    slugs.push(slug);
  } else {
    slugs.push(...slug);
  }
  const partitions = [];
  for (let i = 0; i < slugs.length; i += PARTITION_SIZE) {
    partitions.push(slugs.slice(i, i + PARTITION_SIZE));
  }

  return Promise.all(partitions.map((partition) => fetchSubmissionsForSlugs(token, partition, fetchAll))).then((submissionLists) => {
    const submissions = [];
    for (let i = 0; i < submissionLists.length; i++) {
      submissions.push(...submissionLists[i]);
    }
    return submissions;
  });
}

function fetchSubmissionsForSlugs(token, slugs, fetchAll) {
  const encodedCourseSlug = encodeURIComponent(courseSlug);
  const encodedSlug = encodeURIComponent(slugs.join(","));

  let url = backendUrl + `telemetry/answers/?course_name=${encodedCourseSlug}&exercise_slug=${encodedSlug}`;
  if (fetchAll) {
    url = `${url}&all=true`;
  }
  return fetch(url, {
    headers: {
        Authorization: `Token ${token}`,
    },
  }).then((response) => response.json());
}