import { fetchSubmissions } from "./client";

export function markCompletedExercises(token) {
  const exerciseList = document.querySelector(".exercise-list");
  if (!exerciseList) {
    return;
  }

  const exercises = exerciseList.querySelectorAll("li.exercise");
  const slugs = Array.from(exercises).map((exercise) => exercise.dataset.slug);

  fetchSubmissions(token, slugs).then(submissions => markSubmissions(submissions, exerciseList));
}

function markSubmissions(submissions, exerciseList) {
  if (!submissions) {
    return;
  }

  submissions.forEach(submission => {
    if (submission.points === 0) {
      return;
    }

    const slug = submission?.exercise?.slug;
    if (!slug) {
      return;
    }

    const exercise = exerciseList.querySelector(`li.exercise[data-slug="${slug}"]`);
    if (!exercise) {
      return;
    }

    if (submission.points < 1) {
      exercise.classList.add("partially-completed");
    } else {
      exercise.classList.add("completed");
    }

  });
}