export function initFiles(editor) {
  files = {};

  const fileContents = editor.querySelectorAll(".file-content");
  fileContents.forEach((fileContent) => {
    const filename = fileContent.getAttribute("data-filename");
    const code = fileContent.textContent;
    files[filename] = { code };
  });

  return files;
}

export function updateFiles(files, newFiles) {
  for (let filename in newFiles) {
    files[filename] = newFiles[filename];
  }
}

export function buildHTML(files) {
  let htmlContent = "";
  let cssContent = "";
  let jsContent = "";

  for (let filename in files) {
    const code = files[filename].code;

    if (filename.endsWith(".html")) {
      htmlContent += code;
    } else if (filename.endsWith(".js")) {
      jsContent += `<script>${code}</script>\n`;
    } else if (filename.endsWith(".css")) {
      cssContent += `<style>${code}</style>\n`;
    }
  }

  return `<style>${RESET_CSS}</style>\n${cssContent}${htmlContent}${jsContent}`;
}

const RESET_CSS = `
/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}
/* Active Handout custom iframe reset */
body {
width: 100vw;
height: 100vh;
margin: 0 auto;
padding: 0;
font-family: sans-serif;
overflow: hidden;
}
`;
