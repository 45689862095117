import { fetchSubmissions } from "./client";

export function showSubmissionsIfAvailable(token) {
  const submissionContainer = document.querySelector(".ah-submission-container")
  if (!submissionContainer) {
    return;
  }

  const submissionViewer = submissionContainer.querySelector(".ah-submission-viewer");
  if (!submissionViewer) {
    return;
  }

  if (!token) {
    submissionViewer.innerHTML = "Você precisa estar logado para ver suas submissões.";
    submissionContainer.classList.remove("hidden");
    return;
  }

  fetchSubmissions(token, submissionViewer.dataset.slug, true).then(submissions => initSubmissionList(submissions, submissionViewer, submissionContainer));
}

function initSubmissionList(submissions, submissionViewer, submissionContainer) {
  if (submissions?.length) {
    submissionViewer.innerHTML = "";
    
    const submissionTitle = document.createElement("p");
    submissionTitle.classList.add("ah-code-submission-title");
    submissionViewer.appendChild(submissionTitle);
  
    const [fileEditor, fileTab, filecontentContainer] = createCodeEditor(submissionViewer);
  
    submissions.sort((a, b) => {
      const subA = a.submission_date;
      const subB = b.submission_date;
      if (subA > subB) return -1;
      if (subA < subB) return 1;
      return 0;
    });
    console.log(submissions);
    
    const submissionList = document.createElement("ul");
    submissionList.classList.add("ah-code-submission-list");
    submissions.forEach((submission, idx) => {
      const submissionItem = document.createElement("li");
  
      const submissionItemContent = document.createElement("div");
      submissionItemContent.classList.add("ah-code-submission-item");
      submissionItemContent.innerText = `${submission.log.passing_tests}/${submission.log.total_tests} testes passando em ${new Date(submission.submission_date).toLocaleString("pt-BR")}`;
      
      const loadSubmissionButton = document.createElement("button");
      loadSubmissionButton.classList.add("ah-button");
      loadSubmissionButton.innerText = "Mostrar código";
      
      loadSubmissionButton.addEventListener("click", (event) => {
        event.preventDefault();
        loadSubmission(submission, fileEditor, fileTab, filecontentContainer, submissionTitle);
      });
      
      submissionItemContent.appendChild(loadSubmissionButton);
      submissionItem.appendChild(submissionItemContent);
      submissionList.appendChild(submissionItem);
  
      if (idx === 0) {
        loadSubmission(submission, fileEditor, fileTab, filecontentContainer, submissionTitle);
      }
    })
      
    submissionViewer.appendChild(submissionList);
  }

  submissionContainer.classList.remove("hidden");
}

function createCodeEditor(submissionViewer) {
  const fileEditor = document.createElement("div");
  fileEditor.classList.add("file-editor");
  fileEditor.classList.add("ah-code-submission-editor");

  const fileTab = document.createElement("ul");
  fileTab.classList.add("file-tab");

  const filecontentContainer = document.createElement("div");
  filecontentContainer.classList.add("file-content-container");

  fileEditor.appendChild(fileTab);
  fileEditor.appendChild(filecontentContainer);
  submissionViewer.appendChild(fileEditor);

  return [fileEditor, fileTab, filecontentContainer];
}

function loadSubmission(submission, fileEditor, fileTab, filecontentContainer, submissionTitle) {
  const studentInput = submission.log.student_input;

  submissionTitle.innerText = `Submetido em ${new Date(submission.submission_date).toLocaleString("pt-BR")} (${submission.log.passing_tests}/${submission.log.total_tests} testes passando)`;

  const selectedFilename = fileTab.querySelector(".tab.active")?.innerText;
  const hasSelectedFile = !!selectedFilename?.length;

  if (!hasSelectedFile) {
    fileEditor.style.setProperty("--current-file", 0);
  }
  
  fileTab.innerHTML = "";
  filecontentContainer.innerHTML = "";

  let isFirst = true;
  for (const filename in studentInput) {
    const code = studentInput[filename];

    const isActive = selectedFilename === filename || (isFirst && !hasSelectedFile);
    fileTab.appendChild(createFileTabItem(filename, isActive));
    filecontentContainer.appendChild(createFileContentElement(filename, code, isActive));
    isFirst = false;
  }

  // This should be added by the main extension
  if (window.initEditor) {
    window.initEditor(fileEditor);
  }
}

function createFileTabItem(filename, isActive) {
  const fileTabItem = document.createElement("li");
  fileTabItem.classList.add("tab");
  if (isActive) {
    fileTabItem.classList.add("active");
  }
  fileTabItem.innerHTML = `
    <svg class="svg-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
    ${filename}
  `;

  return fileTabItem;
}

function createFileContentElement(filename, code, isActive) {
  const filecontent = document.createElement("div");
  filecontent.classList.add("file-content");
  if (isActive) {
    filecontent.classList.add("active");
  }
  filecontent.setAttribute("data-readonly", "true");
  filecontent.setAttribute("data-language", guessLanguageFromFilename(filename));
  filecontent.setAttribute("data-filename", filename);
  filecontent.innerHTML = code;

  return filecontent;
}

function guessLanguageFromFilename(filename) {
  const extensions = {
    "py": "python",
    "js": "javascript",
    "html": "html",
    "css": "css",
    "java": "java",
    "c": "c",
    "cpp": "cpp",
    "cs": "csharp",
    "go": "go",
    "hs": "haskell",
    "kt": "kotlin",
    "php": "php",
    "pl": "perl",
    "rb": "ruby",
    "rs": "rust",
    "scala": "scala",
    "swift": "swift",
    "ts": "typescript",
  }
  const extension = filename.split(".").pop();

  if (extension in extensions) {
    return extensions[extension];
  }
  return "text";
}