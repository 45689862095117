export function approximatelyEqual(a, b, epsilon) {
  if (!epsilon) epsilon = 0.1;
  return Math.abs(a - b) < epsilon;
}

export function allEqualRects(preview, expected) {
  if (preview.length !== expected.length) return false;

  for (let i = 0; i < preview.length; i++) {
    for (let attr of ["x", "y", "width", "height"]) {
      if (!approximatelyEqual(preview[i][attr], expected[i][attr], 5))
        return false;
    }
  }

  return true;
}

export function deepCopy(dict) {
  return JSON.parse(JSON.stringify(dict));
}

export function getAllRects(container) {
  const elements = listAllElements(container.shadowRoot);
  const containerRect = container.getBoundingClientRect();
  return elements.map((element) => getRect(element, containerRect));
}

function listAllElements(parent) {
  let elements = [];
  for (let child of parent.childNodes) {
    if (
      child.nodeType === Node.ELEMENT_NODE &&
      ["STYLE", "SCRIPT"].indexOf(child.tagName) < 0
    ) {
      elements.push(child);
      elements = elements.concat(listAllElements(child));
    }
  }
  return elements;
}

function getRect(element, containerRect) {
  const rect = element.getBoundingClientRect();
  return {
    x: rect.x - containerRect.x,
    y: rect.y - containerRect.y,
    width: rect.width,
    height: rect.height,
  };
}
