import {
  extractFilename,
  queryAnswerFiles,
  queryAnswerFromExercise,
  queryAnswerFromPlayground,
  queryEditor,
  queryExerciseFromPlayground,
  queryExpectedResult,
  queryPlaygrounds,
  queryPreview,
  queryTestButtonFromExercise,
} from "./queries";
import { allEqualRects, deepCopy, getAllRects } from "../dom-utils";
import { buildHTML, initFiles, updateFiles } from "./files";

export function initCSSPlugin() {
  const playgrounds = queryPlaygrounds();
  playgrounds.forEach(initPlayground);
}

function initPlayground(playground) {
  const exercise = queryExerciseFromPlayground(playground);
  const editor = queryEditor(playground);
  const preview = queryPreview(playground);
  const testButton = queryTestButtonFromExercise(exercise);
  let files = initFiles(editor);
  updatePreview(preview, files);

  editor.addEventListener("contentchanged", (ev) => {
    updateFiles(files, ev.detail.files);
    updatePreview(preview, files);

    if (testButton) {
      testButton.disabled = false;
    }
    if (exercise) {
      resetExerciseStatus(exercise);
      if (window.clientDB?.removeValue) clientDB.removeValue(exercise);
    }
  });

  if (playground.classList.contains("with-expected-result")) {
    buildExpectedResult(playground, files);
    testButton?.addEventListener(
      "click",
      buildTestClickListener(playground, files)
    );
  }

  if (exercise && window.getSubmissionCache) {
    const { value: filesStr, submitted } = getSubmissionCache(exercise);
    if (filesStr) {
      const { correct } = JSON.parse(filesStr);
      updateClasses(exercise, correct, true);

      if (!submitted) {
        sendAndCacheData(exercise, filesStr, correct ? 1 : 0);
      }
    }
  }
}

function buildTestClickListener(playground, files) {
  return () => {
    const exercise = queryExerciseFromPlayground(playground);
    const previewContainer = queryPreview(playground);
    const expectedContainer = queryExpectedResult(playground);

    resetExerciseStatus(exercise);

    const previewRects = getAllRects(previewContainer);
    const expectedRects = getAllRects(expectedContainer);

    const correct = allEqualRects(previewRects, expectedRects);
    updateClasses(exercise, correct);
    handleTelemetry(exercise, files, correct);
  };
}

function updateClasses(exercise, correct, ignoreWrongClass) {
  if (correct) {
    exercise.classList.add("done");
    const testButton = queryTestButtonFromExercise(exercise);
    testButton.disabled = true;
    showAnswer(exercise);
  } else {
    if (!ignoreWrongClass) {
      exercise.classList.add("wrong");
    }
    hideAnswer(exercise);
  }
}

function showAnswer(exercise) {
  const answer = queryAnswerFromExercise(exercise);
  answer.style.display = "inherit";
}

function hideAnswer(exercise) {
  const answer = queryAnswerFromExercise(exercise);
  answer.style.display = "none";
}

function handleTelemetry(exercise, files, correct) {
  const filesStr = JSON.stringify({ files, correct });
  if (window.sendAndCacheData)
    sendAndCacheData(exercise, filesStr, correct ? 1 : 0);
}

function resetExerciseStatus(exercise) {
  exercise.classList.remove("done");
  exercise.classList.remove("wrong");
  hideAnswer(exercise);
}

function buildExpectedResult(playground, files) {
  files = deepCopy(files);

  const answer = queryAnswerFromPlayground(playground);
  const answerFiles = queryAnswerFiles(answer);

  answerFiles.forEach((answerFile) => {
    const filename = extractFilename(answerFile);
    if (!files[filename]) {
      files[filename] = {};
    }
    files[filename].code = answerFile.textContent;
  });

  const html = buildHTML(files);
  updateShadow(queryExpectedResult(playground), html);
}

function updatePreview(preview, files) {
  updateShadow(preview, buildHTML(files));
}

function updateShadow(parent, html) {
  let shadowDOM = parent.shadowRoot;
  if (!shadowDOM) {
    shadowDOM = parent.attachShadow({ mode: "open" });
  }
  shadowDOM.innerHTML = html;
}
